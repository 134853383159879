import * as React from 'react';
import { action, decorate, observable, computed, runInAction } from 'mobx';
import { addRoutesToRouteMap, googleAnalytics, routeMap } from '@mq/volt-amc-container';

import currentUserStore from 'stores/CurrentUserStore';
import UserStore from './../UserStore.js';
import leavingModalStore from '../LeavingModalStore.js';

import logger from 'utils/logger';
import { camelCase, pathParamsToObject, resolveOptions, urlMatcher } from './utils.js';
import { Router } from 'director/build/director';

import lazyLoader from './LazyLoader.js';
import RouteFactoryMap from './RouteFactoryMap.js';
import registerRoutes from './registerRoutes.js';

export class RouterStore {
  constructor(routeMap: Object) {
    // Instead of instantiating and configuring new Router() outside of
    // RouterStore like we use to, we do it here so we can register() routes to
    // routerStore and to router with router.mount().
    const routerStore = this;
    this.router = new Router();
    this.router.configure({
      notfound: () => routerStore.notFound(this.router.newPath),
      html5history: true,
    });
    // Register routes.
    this.register(routeMap);
    // Add popstate listener to pass newPath from router to routerStore.
    window.addEventListener('popstate', function () {
      // Now we set router.newPath.
      routerStore.router.newPath = window.location.pathname + window.location.search;

      // Merge routerStore.router.goOptions and current window location
      // https://marqeta.atlassian.net/browse/PS-26331
      const urlParams = new URLSearchParams(window.location.search);
      const goOptions = Object.fromEntries(urlParams);
      routerStore.router.goOptions = goOptions;
    });
    // pd redirect stuff.
    this.pdRedirect = null;
    if (window.location.search.startsWith('?pd_redirect=')) {
      const path = decodeURIComponent(window.location.search.slice(13)) + '/reports';
      this.pdRedirect = path;
    }
  }

  // values
  currentView: ?React.Element<*> = null;
  deferredRoute: ?Function = null;
  events: ?Map = new Map();
  isSigningOut: boolean = false;
  loadingView: boolean = false;
  pathMap: { [string]: Object } = {};
  pathParams: ?Object = {};
  pathWithSearch: string = '';
  preventedRedirectRoutes: Array<string> = [];
  route: ?Object = {};
  url: ?Object = new URL(window.location);
  routeMap: { [string]: Object } = {};
  router: Object;
  supportedEvents: ?[string] = ['route'];
  // deprecated values
  currentParams: ?Map<*, *> = new Map();
  currentPath: string = '';
  currentPathWithSearch: string = '';
  currentRouteName: string = '';
  currentSearch: string = '';

  setIsSigningOut(isSigningOut) {
    this.isSigningOut = isSigningOut;
  }

  onBeforeUnload(event) {
    if (this.isSigningOut) {
      return;
    }

    event.preventDefault();
    event.returnValue = true;

    return true;
  }

  registerOnPageLeave(modalTexts) {
    if (this.preventedRedirectRoutes.includes(this.url.pathname)) {
      return;
    }

    this.preventedRedirectRoutes.push(this.url.pathname);
    leavingModalStore.setLeavingModalTexts(modalTexts);
    window.addEventListener('beforeunload', this.onBeforeUnload);
  }

  unregisterOnPageLeave(dropTexts = true) {
    const routesCopy = [...this.preventedRedirectRoutes];
    routesCopy.splice(routesCopy.indexOf(this.url.pathname), 1);
    this.preventedRedirectRoutes = routesCopy;

    dropTexts && leavingModalStore.setLeavingModalTexts({});
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  shouldPreventRedirect(routeName, options = {}) {
    if (
      this.preventedRedirectRoutes.includes(this.url.pathname) &&
      !leavingModalStore.showLeavingModal
    ) {
      leavingModalStore.setShowLeavingModal(true);
      leavingModalStore.setRouteParamsToLeave({ routeName, params: options?.params, options });
      return true;
    }

    leavingModalStore.showLeavingModal && leavingModalStore.setShowLeavingModal(false);
    return false;
  }

  /**
   * Go! Navigate to a path.
   *
   * NOTE: For backwards compatibility, calling go(routeName, params, oldOptions)
   * or go(routeId, params, oldOptions) is still supported, but deprecated.
   * However, calling go(stringPath, params) is no longer supported. Instead
   * call go(stringPath, options) and put query params in options.params.
   *
   * @param {(?Object|?string)} pathOrRoute Path (string) to navigate to. Route
   *          (object) is also supported for backwards compatibility.
   * @param {?{Object} } options New options. Supported properties = { params, state, replaceState, skipGaPageView }
   * @param {(?boolean|?{[string]:boolean} )} oldOptions (deprecated) Old options for
   *          backwards compatibility. Do not use.
   */
  go(
    pathOrRoute: ?string | ?Object,
    optionsOrParams: ?Object = {},
    oldOptions: ?{ [string]: boolean } | ?boolean
  ) {
    const isPath =
      !pathOrRoute || (typeof pathOrRoute === 'string' && ['/', '?', '#'].includes(pathOrRoute[0]));

    // For backwards compatibility this logic supports the old ways of calling
    // go(). IMPORTANT: This is deprecated and should eventually be removed
    // because it circumvents the Director Router API, which is used under the
    // hood.
    if (!isPath) {
      const { skipGaPageview, replaceState } = resolveOptions(oldOptions);
      const route = this.getRoute(pathOrRoute);
      if (!route) {
        return this.linkFailed(window.location.pathname);
      }
      // Do nothing if we should prevent redirect.
      if (this.shouldPreventRedirect(pathOrRoute, { params: optionsOrParams, options: oldOptions }))
        return;
      // Call routerStore.setRoute() directly.
      return this.setRoute(route.path, {
        route,
        params: optionsOrParams,
        skipGaPageview,
        replaceState,
      });
    }

    // If path starts with ? or #, prefix it with current pathname.
    if (typeof pathOrRoute === 'string' && ['?', '#'].includes(pathOrRoute[0])) {
      pathOrRoute = window.location.pathname + pathOrRoute;
    }

    // If path is falsy and options exist, use current path.
    if (!pathOrRoute && optionsOrParams) {
      pathOrRoute = this.currentPathWithSearch;
    }

    // Do nothing if we should prevent redirect.
    if (this.shouldPreventRedirect(pathOrRoute, optionsOrParams)) return;

    // We assume optionsOrParams is options, since pathOrRoute is a string path.
    // But for backwards compatibility, if optionsOrParams has no supported
    // options, treat it as params (the deprecated way of calling go()).
    if (
      oldOptions ||
      (optionsOrParams.params === undefined &&
        optionsOrParams.state === undefined &&
        optionsOrParams.replaceState === undefined &&
        optionsOrParams.skipGaPageView === undefined)
    ) {
      optionsOrParams = { params: optionsOrParams };
      if (oldOptions) {
        const { skipGaPageview, replaceState } = resolveOptions(oldOptions);
        optionsOrParams.replaceState = replaceState;
        optionsOrParams.skipGaPageview = skipGaPageview;
      }
    }

    // At this point we know pathOrRoute is a path (the non-deprecated
    // behavior). So first we cache newPath so we can pass it between router and
    // routerStore. This is necessary because we are using router.dispatch()
    // instead of router.setRoute(). router.dispatch() runs router events, but
    // does not update the URL, allowing us to control when/how to update it;
    // whereas setRoute() also runs router events while also updating the URL.
    // If we want to update the URL here, we can change this behavior and remove
    // the need for setting router.newPath, but we would need to perform any
    // checks (such as shouldPreventRedirect) first.
    this.router.goOptions = optionsOrParams;
    this.router.newPath = pathOrRoute;
    this.router.dispatch('on', pathOrRoute);
  }

  link(pathOrRouteNameOrKey: ?string, params: ?{ [string]: string }) {
    const route = this.getRoute(pathOrRouteNameOrKey);
    // $FlowFixMe
    if (route) {
      const searchParams = new URLSearchParams(params);
      const searchString = '?' + searchParams.toString();

      if (route.name) {
        return `${route.path || ''}${searchString}`;
      }
    }

    return this.pathWithSearch;
  }

  /**
   * Logic for navigating to a new route.
   *
   * @param {string} newPath New path to navigate to.
   * @param {?Object} options Options.
   */

  getPathParams(pathParams, route) {
    // If pathParams exist, merge path with path params.
    if (pathParams instanceof Array && pathParams?.length) {
      return (pathParams = pathParamsToObject(pathParams, route));
      // If route is same, use existing pathParams.
    } else if (this.route && this.route.id === route.id) {
      return (pathParams = this.pathParams);
      // Otherwise reset pathParams.
    } else {
      return (pathParams = {});
    }
  }

  // Helper function to create and return the URL object
  createUrl(newPath, params) {
    const url = new URL(newPath, window.location.origin);

    // If params exist, add them to the url.
    if (params) {
      const newParams = params instanceof URLSearchParams ? params : new URLSearchParams(params);
      newParams.forEach((value, key) => {
        url.searchParams.set(key, value);
      });
    }
    // If program param exists, keep it.
    if (this.url.searchParams.has('program') && !url.searchParams.has('program')) {
      url.searchParams.set('program', this.url.searchParams.get('program'));
    }

    return url;
  }
  setRoute(newPath: string, options: ?Object) {
    const { params, replaceState, skipGaPageview, state = {} } = options;
    let { pathParams, route } = options;

    // For backwards compatibility, if replaceState is true, set newPath to
    // current path.
    if (replaceState && this.route) {
      newPath = this.url.pathname;
      route = this.route;
    }

    // If route doesn't exist, redirect to notFound.
    if (!route?.path) {
      return this.notFound(newPath);
    }

    pathParams = this.getPathParams(pathParams, route);

    const url = this.createUrl(newPath, params);

    // Update routerStore properties.
    const shouldLoadView = this.route.name !== route.name || !route.view;
    this.url = url;
    this.route = route;
    this.pathParams = pathParams;
    this.pathWithSearch = this.url.pathname + this.url.search;

    // For backwards compatibility, update deprecated routerStore properties.
    this.currentPathWithSearch = this.pathWithSearch;
    this.currentParams = this.url.searchParams;
    this.currentPath = this.url.pathname;
    this.currentSearch = this.url.search;
    this.currentRouteName = route.name;

    // Load the view.
    if (shouldLoadView) this.loadView(route);

    // This is where we actually navigate/update the URL with either pushState
    // or replaceState. This replaces the old autorun() behavior, which used
    // mobx to detect changes to routerStore.pathWithSearch.
    if (this.url.pathname !== '/auth-callback') {
      state.url = {
        pathname: this.url.pathname,
        search: this.url.search,
        pathParams: pathParams,
        routePath: this.route.path,
      };
      if (replaceState) {
        window.history.replaceState(state, null, this.pathWithSearch);
      } else if (this.pathWithSearch !== window.location.pathname + window.location.search) {
        window.history.pushState(state, null, this.pathWithSearch);
      }
    }

    // Emit route event.
    this.emit('route', this);

    // Run analytics.
    let orgName = currentUserStore?.userStore?.userOrgName || '';
    if (googleAnalytics && !skipGaPageview) {
      googleAnalytics.send({
        hitType: 'pageview',
        page: this.pathWithSearch,
        title: route.name,
        orgName: orgName,
      });
    }
    if (currentUserStore && currentUserStore.userStore) {
      const {
        redsea_first_name: first_name,
        redsea_last_name: last_name,
        redsea_email: email,
        redsea_phone: phone,
        redsea_token,
      } = currentUserStore.userStore;
      window.analytics &&
        window.analytics.identify(redsea_token, {
          first_name,
          last_name,
          email,
          phone,
        });
    }
  }

  getPath(index: number) {
    return this.router.getRoute(index);
  }

  async loadView(route = {}) {
    if (route.view !== this.currentView && !this.loadingView) {
      this.loadingView = true;
    }

    // If no view exists, it needs to be lazy loaded.
    if (!route.view) {
      let factory;
      try {
        factory = route.importer;
        if (!factory) {
          throw new Error(`Please add ${route.id} to the Route Factory Map`);
        }
      } catch (e) {
        logger.error(e);
      }
      const loadedView = await lazyLoader(factory);
      if (this.routeMap[route.view]) {
        this.routeMap[route.view].view = loadedView;
      }
      route.view = loadedView;
    }

    // setTimeout to keep the loading bar a little more consistent
    setTimeout(() => {
      runInAction(() => {
        this.loadingView && (this.loadingView = false);
        this.currentView = route.view;
        // Segment page view tracking
        window.analytics && window.analytics.page();
      });
    }, 100);
  }

  updateParams(newParams: string | Object, replaceState = false) {
    // Convert newParams to URLSearchParams.
    const searchParams =
      newParams instanceof URLSearchParams ? newParams : new URLSearchParams(newParams);
    // Merge with existing params.
    this.url.searchParams.forEach((value, key) => {
      if (!searchParams.has(key)) searchParams.set(key, value);
    });
    // Update route if new params are different from existing params.
    const paramsAreDifferent = '?' + searchParams.toString() !== this.url.search;
    if (paramsAreDifferent && this.currentView) {
      this.setRoute(this.url.pathname ? this.url.pathname : '', {
        params: searchParams,
        skipGaPageview: false,
        replaceState,
        route: this.route,
      });
    }
  }

  replaceParams(newParams: string | Object, skipGaPageview: ?Boolean = false) {
    if (this.currentView) {
      this.setRoute(this.url.pathname ? this.url.pathname : '', {
        params: newParams,
        skipGaPageview,
        route: this.route,
      });
    }
  }

  register(routes) {
    const routerStore = this;

    // If routes is a route map, convert it to an array.
    if (typeof routes === 'object' && !routes.path) {
      routes = Object.keys(routes).reduce((result, id) => {
        result.push(routes[id]);
        return result;
      }, []);
    }
    // Ensure routes is an array.
    if (!(routes instanceof Array)) {
      routes = [routes];
    }
    // Loop through routes...
    routes.forEach((route) => {
      // Route must be an object with name and path properties.
      if (typeof route !== 'object' || !route.name || !route.path) {
        return;
      }
      // Add id if it doesn't exist.
      if (!route.id) {
        route.id = camelCase(route.name);
      }
      // Add importer (factory) to load view on initial load.
      if (!route.importer) {
        route.importer = RouteFactoryMap[route.id];
      }
      // If route already exists, skip it.
      if (this.routeMap[route.id] || this.pathMap[route.path]) {
        console.warn(`Skipped. Route ${route.id || route.path} already exists.`);
        return;
      }
      // Register the route.
      this.routeMap[route.id] = route;
      this.pathMap[route.path] = this.routeMap[route.id];
      // Also add to routeMap in volt-amc-container to remain sync.
      if (!routeMap[route.id]) addRoutesToRouteMap(route);

      // Register/mount route in router.
      const mountConfig = {
        [route.path]: {
          on(...pathParams) {
            // Pass router.newPath to route (use route.path as fallback).
            const newPath =
              routerStore.router.newPath || window.location.pathname + window.location.search;

            // Call setRoute() instead of go(). go() calls router.setRoute(), which
            // triggers this route's on() callback. This follows Director
            // Router's API and gives us its built in features, such as dynamic
            // routing.
            routerStore.setRoute(newPath, {
              ...routerStore.router.goOptions,
              route,
              pathParams,
            });

            // Call route's on() callback.
            if (typeof route.router?.on === 'function') {
              route.router.on(...pathParams);
            }

            // Reset router.newPath.
            routerStore.router.newPath = '';
          },
        },
      };
      // Pass route.router[event] to router's mount configuration.
      if (route.router) {
        ['once', 'before', 'after'].forEach((event) => {
          if (typeof route.router[event] === 'function') {
            mountConfig[route.path][event] = route.router[event];
          }
        });
      }
      // Mount route to router.
      this.router.mount(mountConfig);
    });
  }

  on(eventType, action) {
    if (!this.supportedEvents.includes(eventType)) return;
    if (!this.events.get(eventType)) {
      this.events.set(eventType, new Map());
    }
    this.events.get(eventType).set(action, action);
    return this;
  }

  off(eventType, action) {
    if (!this.supportedEvents.includes(eventType)) return;
    if (this.events.has(eventType)) {
      const event = this.events.get(eventType);
      event.delete(action);
    }
  }

  emit(eventType, ...args) {
    if (!this.supportedEvents.includes(eventType)) return;
    if (this.events.get(eventType)) {
      this.events.get(eventType).forEach((action) => action(...args));
    }
  }

  deferRoute(
    routeName: string,
    path: string,
    view: React.Element<*>,
    params: ?{ [string]: string }
  ) {
    this.deferredRoute = this.route.bind(this, routeName, path, view, params);
  }

  linkFailed(path: string) {
    if (!path) {
      path = this.pathWithSearch || window.location.pathname + window.location.search;
    }
    if (googleAnalytics) {
      googleAnalytics.event({
        category: 'Application Errors',
        action: 'Page Not Found',
        label: `Linked to ${path}`,
      });
    }
    this.setRoute(path, {
      params: { attemptedRoute: path },
      route: this.routeMap.notFoundView,
    });
  }

  notFound(path: string) {
    if (!path) {
      path = this.pathWithSearch || window.location.pathname + window.location.search;
    }
    if (googleAnalytics) {
      googleAnalytics.event({
        category: 'User Errors',
        action: 'Page Not Found',
        label: `Navigated to ${path}`,
      });
    }
    this.setRoute(path, {
      route: this.routeMap.notFoundView,
    });
  }

  // computed
  get currentParamsAsObject(): Object {
    const paramsObject = {};
    if (this.url.searchParams) {
      this.url.searchParams.forEach((value, key) => {
        paramsObject[key] = value;
      });
    }
    return paramsObject;
  }

  get currentProgramShortCodeInUrl(): string {
    return this.url.searchParams.get('program');
  }

  get currentEnvironmentInUrl(): string {
    return this.url.searchParams.get('env');
  }

  // Helper functions
  paramIs(param: string, value: string) {
    const searchParams = this.url.searchParams;
    const currentValue = searchParams.get(param);
    return currentValue && currentValue === value;
  }

  userIsLoggedIn(): boolean {
    let userStore = UserStore;
    if (currentUserStore && currentUserStore.userStore) {
      userStore = currentUserStore.userStore;
    }
    return userStore.loggedIn();
  }

  getRoute(pathOrRouteKeyOrName: string) {
    if (!pathOrRouteKeyOrName) return this.route;
    return urlMatcher(pathOrRouteKeyOrName, this);
  }

  objectToSearchString(obj: { [string]: string }) {
    const searchParams = obj instanceof URLSearchParams ? obj : new URLSearchParams(obj);
    return '?' + searchParams.toString();
  }
}

decorate(RouterStore, {
  // values
  currentRouteName: observable,
  currentPath: observable,
  currentSearch: observable,
  currentPathWithSearch: observable,
  currentView: observable,
  currentParams: observable,
  pathParams: observable,
  pathWithSearch: observable,
  route: observable,
  deferredRoute: observable,
  loadingView: observable,
  url: observable,

  // actions
  go: action.bound,
  setRoute: action.bound,
  loadView: action.bound,
  updateParams: action.bound,
  replaceParams: action.bound,
  deferRoute: action.bound,
  linkFailed: action.bound,
  notFound: action.bound,
  register: action.bound,
  registerOnPageLeave: action.bound,
  unregisterOnPageLeave: action.bound,
  setIsSigningOut: action.bound,

  // computed
  currentParamsAsObject: computed,
  currentProgramShortCodeInUrl: computed,
  currentEnvironmentInUrl: computed,
});

// Instantiate new RouterStore.
const routerStore = new RouterStore(routeMap);

// Register Routes via the 2021 routerStore.register()
registerRoutes(routerStore);

// Initialize router last.
routerStore.router.init();

export default routerStore;
